


import { Component, Vue, Mixins } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";


@Component
export default class CustomerAlertsComponent extends Vue {
  /**
   * The table pagination state data.
   * This will change at every page switch
   */
  public pageConfig = {
    pageNumber: 1,
    pageLimit: 10,
    loadText: "Load more"
  };

  /**
   * All alerts
   */
  public customerAlerts = [];

  /**
   * The count of all the customers in the database
   */
  public totalAlerts = 0;

  public loadMoreData() {
    this.pageConfig.pageNumber++;
    this.pageConfig.loadText = "Loading...";
    this.getCustomerAlerts();
  }

  async getCustomerAlerts() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "customer-retention/customerAlerts",
        {
          params: {
            brokerId: this.$brokerId,
            pageLimit: this.pageConfig.pageLimit,
            pageNumber: this.pageConfig.pageNumber
          }
        }
      );
      this.totalAlerts = response.data.totalCount;
      this.customerAlerts.push(...response.data.data);
      this.pageConfig.loadText = "Load more";
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  mounted() {
    this.$store.state.wemloLoader = true;
    this.getCustomerAlerts();
  }
}
